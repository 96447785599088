@import 'assets/styles/_mixins';

.root {
  @include transform;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-navbar-overlay);
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 10rem 2.5rem 2.5rem 2.5rem;
  background: url('~assets/img/background.jpg') center/cover no-repeat;
  transition-delay: 200ms;
  transition-duration: 500ms;
}

.active {
  & > div {
    font-weight: bold !important;
  }
}

.opened {
  opacity: 1;
  --tw-translate-x: 0px;
}

.closed {
  opacity: 0;
  --tw-translate-x: -100%;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
