@import 'assets/styles/variables';

body {
  margin: 0;
  color: var(--color-white);
  font-family: var(--font-family-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.05em;
  background-color: var(--color-dark-bg);
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

*::selection {
  color: var(--color-white);
  background: var(--color-primary);
}

*::-moz-selection {
  color: var(--color-white);
  background: var(--color-primary);
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  background-color: var(--scrollbar-color);
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: 10px;
}

@font-face {
  font-family: 'Audiowide';
  src: local('Audiowide'),
    url('./fonts/Audiowide-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MagistralC';
  src: local('MagistralC'), url('fonts/MagistralC.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('fonts/Montserrat.ttf') format('truetype');
}
