.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-background);
  width: 100%;
  height: 100%;
  background-color: var(--color-dark-bg);
  transition: background-image 0.3s;
}
