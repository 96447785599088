@import 'assets/styles/_adaptive';

.root {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--color-white);
  line-height: 20px;

  // START FAMILY
  &.family-base {
    font-family: var(--font-family-base);
  }

  &.family-cyrillic {
    font-family: var(--font-family-cyrillic);
  }

  &.family-latin {
    font-family: var(--font-family-latin);
  }
  // END FAMILY

  // START FORMATS
  &.format-inherit {
    font-size: inherit;
    line-height: inherit;
  }

  &.format-custom {
  }

  &.format-xs {
    font-size: 12px;
  }

  &.format-s {
    font-size: 16px;
  }

  &.format-m {
    font-size: 22px;
    line-height: 30px;

    @include mediaMaxXl {
      font-size: 18px;
      line-height: 24px;
    }

    @include mediaMaxLg {
      font-size: 16px;
      line-height: 22px;
    }

    @include mediaMaxMd {
      font-size: 14px;
      line-height: 22px;
    }

    @include mediaMaxSm {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &.format-l {
    font-size: 30px;
    line-height: 35px;

    @include mediaMaxSm {
      font-size: 24px;
      line-height: 20px;
    }
  }

  &.format-xl {
    font-size: 40px;
    line-height: 46px;

    @include mediaMaxXxl {
      font-size: 34px;
      line-height: 40px;
    }

    @include mediaMaxXl {
      font-size: 30px;
      line-height: 34px;
    }

    @include mediaMaxLg {
      font-size: 26px;
      line-height: 30px;
    }

    @include mediaMaxMd {
      font-size: 20px;
      line-height: 24px;
    }

    @include mediaMaxSm {
      font-size: 16px;
      line-height: 16px;
    }
  }
  // END FORMATS

  // START WEIGHTS
  &.weight-normal {
    font-weight: 400;
  }

  &.weight-medium {
    font-weight: 500;
  }

  &.weight-bold {
    font-weight: 700;
  }
  // END WEIGHTS

  // START ALIGN
  &.align-left {
    text-align: left;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  &.align-inherit {
    text-align: inherit;
  }
  // END ALIGN

  // START COLORS
  &.colorType-link {
    color: var(--color-white);
    text-decoration: none;
    transition: color 0.4s ease;

    &:hover {
      color: var(--color-primary);
      cursor: pointer;
    }
  }

  &.colorType-primary {
    color: var(--color-primary);
  }

  &.colorType-success {
    color: var(--color-success);
  }

  &.colorType-danger {
    color: var(--color-danger);
  }

  &.colorType-inherit {
    color: inherit;
  }

  &.colorType-gray {
    color: var(--color-grey);
  }
  // END COLORS

  // START DECORATION
  &.decoration-none {
    text-decoration: none;
  }

  &.decoration-underline {
    text-decoration: underline;
  }

  &.decoration-line_through {
    text-decoration: line-through;
  }
  // END DECORATION

  // START OTHER PROPS
  &.uppercase {
    text-transform: uppercase;
  }

  &.inline {
    display: inline;
  }

  &.clickable {
    cursor: pointer;
  }
  // END OTHER PROPS
}
