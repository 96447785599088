.button {
  padding: 1rem 1.2rem 1rem 1.6rem;
  border: none;
  cursor: pointer;
}

.notch {
  clip-path: polygon(
    0% 12px,
    12px 0%,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0% 100%
  );
}

.primary {
  background: var(--color-primary);
  transition: transform 0.3s;

  &:hover {
    color: var(--color-white);
    transform: scale(1.1);
  }
}

$anim-duration: 3; // in seconds
$anim-speed: 0.85; // in seconds

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  #{($anim-speed / ($anim-duration + $anim-speed) * 100%)},
  100% {
    left: 100%;
    transition-property: left;
  }
}


.offer {
  @extend .notch;
  @extend .primary;
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--shine-effect);
    animation: shine #{$anim-duration}s ease-in-out infinite;
    content: "";
  }
}
