@import 'assets/styles/_adaptive';

.header {
  position: fixed;
  z-index: var(--z-header);
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  min-height: var(--header-height);
  transition: backdrop-filter 0.4s ease 0.4s;
}

.headerWithBg {
  backdrop-filter: blur(12px) brightness(0.5);
  transition-delay: 0.1s;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 40px;
}

.logo {
  height: 40px;
  color: var(--color-primary);
  cursor: pointer;
}

.active {
  & > div {
    color: var(--color-primary) !important;
  }
}

.menu {
  display: flex;
  gap: 25px;
  align-items: center;
  @include mediaMaxLg {
    display: none;
  }
}

.hamburgerOuter {
  position: relative;
  display: none;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  @include mediaMaxLg {
    display: flex;
  }
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.rotateWithDelay {
  --tw-rotate: 45deg;
  transition-delay: 200ms;
}

.translateY {
  --tw-translate-y: -0.375rem;
}

.hamburgerInner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.25rem;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  @extend .transform;
}

.firstLine {
  position: absolute;
  width: 1.25rem;
  height: 0.125rem;
  background-color: var(--color-white);
  transition-duration: 300ms;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  @extend .transform;
  @extend .ease-in-out;
}

.secondLine {
  position: absolute;
  width: 1.25rem;
  height: 0.125rem;
  background-color: var(--color-white);
  transition-delay: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  @extend .transform;
  @extend .ease-in-out;

  .show {
    width: 1.25rem;
    opacity: 1;
    transition-duration: 200ms;
  }

  .hide {
    width: 0;
    opacity: 0.5;
  }
}

.thirdLine {
  position: absolute;
  width: 1.25rem;
  height: 0.125rem;
  background-color: var(--color-white);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  @extend .transform;
  @extend .ease-in-out;
}
